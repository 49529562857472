import { baseSepolia, optimismSepolia, sepolia } from 'viem/chains';

import type { ConfigEntry } from './config.types';

export const config: ConfigEntry = {
  environmentName: 'dev',
  chains: [sepolia, optimismSepolia, baseSepolia],
  baseUrls: {
    app: 'https://app.dev.endaoment.org',
    marketing: 'https://mktg.dev.endaoment.org',
    api: 'https://api.dev.endaoment.org',
    developers: 'https://developers.dev.endaoment.org',
    docs: 'https://docs.endaoment.org',
    oauth: 'https://oauth.dev.endaoment.org',
  },
  socialLoginChainId: optimismSepolia.id,
  zeroDev: {
    projectId: 'bcba09cd-0884-43c3-98f4-128ebf84572f',
    bundlerRpcUrl: 'https://rpc.zerodev.app/api/v2/bundler/bcba09cd-0884-43c3-98f4-128ebf84572f',
    paymasterRpcUrl: 'https://rpc.zerodev.app/api/v2/paymaster/bcba09cd-0884-43c3-98f4-128ebf84572f',
  },
  privyAppId: 'clv19o5jv06nxtjte9ecj88o0',
  heapAppId: '828912306',
  circleAddress: '0xd31361f706970b0071df3ebb24fec35a4870e096',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  identifiersSecret: '0c8b1114-b048-4f6b-b33e-7ed296442555f5e1d732-6a0c-468e-9c3e-e8ce37e4e56e',
};
