import * as app from './app-routes';
import * as developers from './developers-routes';
import * as docs from './documentation-routes';
import * as marketing from './marketing-routes';

export { formatEinOrUuid } from './utils';
export const routes = { app, marketing, developers, docs };

export {
  assembleSearchParamsForWizards,
  donationWizardParsers,
  fundWizardParsers,
  parseAsNullableUuid,
  portfolioWizardParsers,
  targetAllocationModalParsers,
} from './wizardUtils';
export type { SearchParamsUsedByWizards } from './wizardUtils';
