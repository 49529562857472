import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import { CheckmarkIcon, CopyIcon, ShareIcon2 } from '@endaoment-frontend/ui/icons';

import styles from './CopyTooltip.module.scss';
import { Tooltip } from './Tooltip';

const icons = {
  link: motion.create(CopyIcon),
  copy: motion.create(ShareIcon2),
} as const;

const MotionCheck = motion.create(CheckmarkIcon);

type CopyTooltipProps = {
  displayText: string;
  copyText?: string;
  delay?: number;
  icon?: keyof typeof icons;
  className?: string;
  buttonClassName?: string;
  children?: ReactNode;
};

export const CopyTooltip = ({
  displayText,
  copyText,
  delay = 1250,
  icon = 'copy',
  className,
  buttonClassName,
  children,
}: CopyTooltipProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const handleCopyUrl = useCallback(() => {
    copyToClipboard(copyText ?? displayText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), delay);
  }, [delay, copyToClipboard, copyText, displayText]);

  const Icon = isCopied ? MotionCheck : icons[icon];

  let content = children;
  if (!children) {
    content = (
      <AnimatePresence>
        <Icon
          color='currentColor'
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1 },
          }}
          data-testid='copy-tooltip-icon'
        />
      </AnimatePresence>
    );
  }

  return (
    <Tooltip
      content={isCopied ? 'Copied!' : displayText}
      className={clsx(styles['tooltip'], styles['tooltip__current-color'], className)}>
      <button type='button' onClick={handleCopyUrl} onKeyDown={handleCopyUrl} tabIndex={0} className={buttonClassName}>
        {content}
      </button>
    </Tooltip>
  );
};
