import {
  createParser,
  createSerializer,
  parseAsBoolean,
  parseAsFloat,
  parseAsInteger,
  parseAsJson,
  parseAsString,
  parseAsStringEnum,
} from 'nuqs';
import SuperJSON from 'superjson';
import type { z } from 'zod';

import type { SupportedBroker, UUID } from '@endaoment-frontend/types';
import {
  bigIntSchema,
  brokerageInfoFormSchema,
  donationRecipientSchema,
  evmTokenSchema,
  grantInstructionInputSchema,
  stockLotSchema,
  stockTickerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

const createParserForSchema = <T extends z.ZodType>(schema: T) =>
  createParser<z.infer<T>>({
    parse: v => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return schema.parse(SuperJSON.parse(v));
      } catch {
        return null;
      }
    },
    serialize: v => SuperJSON.stringify(v),
  });
const parseAsUuid = createParser<UUID>({
  parse: v => {
    try {
      return uuidSchema.parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => v.toString(),
});
export const parseAsNullableUuid = createParser<UUID | null | undefined>({
  parse: v => {
    try {
      return uuidSchema.nullish().parse(v);
    } catch {
      return null;
    }
  },
  serialize: v => (v ? v.toString() : ''),
});
const parseAsBigInt = createParserForSchema(bigIntSchema);

export const targetAllocationModalParsers = {
  isTargetAllocationModalOpen: parseAsBoolean.withDefault(false),
  targetAllocationFundId: parseAsUuid,
} as const;

export const donationWizardParsers = {
  isDonationWizardOpen: parseAsBoolean.withDefault(false),
  dwMode: parseAsStringEnum(['brokerage-donation', 'credit-donation', 'erc-donation', 'grant', 'otc-donation']),
  dwRecipient: parseAsJson(v => donationRecipientSchema.parse(v)),
  dwAmount: parseAsBigInt.withDefault(0n),
  creditPledgeAmount: parseAsInteger.withDefault(0),
  dwIncludeTaxReceipt: parseAsBoolean.withDefault(false),
  ercToken: parseAsJson(v => evmTokenSchema.parse(v)),
  grantOriginId: parseAsUuid,
  grantInstructions: parseAsJson(v => grantInstructionInputSchema.omit({ recommendationId: true }).parse(v)),
  otcTokenId: parseAsInteger,
  otcDonationTransactionHash: parseAsString.withDefault(''),
  brokerageTicker: parseAsJson(v => stockTickerSchema.parse(v)),
  brokerageShares: parseAsFloat.withDefault(0),
  brokerageLots: parseAsJson(v => stockLotSchema.array().parse(v)).withDefault([]),
  brokerageBroker: parseAsJson(v => brokerageInfoFormSchema.parse(v)).withDefault({
    // Even though typescript isn't happy about this, undefined is the most optimal default value for the brokerage field
    // it will be forced to be a valid value in the form
    brokerage: undefined as unknown as SupportedBroker,
    brokerageAccountNumber: '',
    brokerageContactName: '',
    brokerageEmail: '',
    brokeragePhone: '',
  }),
  recommendationId: parseAsUuid,
  requestScheduledLiquidation: parseAsBoolean.withDefault(false),
  isRecommendation: parseAsBoolean.withDefault(false),
} as const;

export const fundWizardParsers = {
  isFundWizardOpen: parseAsBoolean.withDefault(false),
  fundWizardMode: parseAsStringEnum(['create', 'migrate']),
  destinationFundId: parseAsNullableUuid,
  newFundName: parseAsString.withDefault(''),
  // Set default to -1 to ensure that the type is always a number and be able to tell that it's not set
  newFundChainId: parseAsInteger.withDefault(-1),
  referralCode: parseAsString.withDefault(''),
  referralSource: parseAsString.withDefault(''),
  migrateAmount: parseAsBigInt.withDefault(0n),
} as const;

export const portfolioWizardParsers = {
  isPortfolioWizardOpen: parseAsBoolean.withDefault(false),
  portfolioWizardFundId: parseAsUuid,
  portfolioWizardPortfolioId: parseAsUuid,
  portfolioWizardAmount: parseAsBigInt.withDefault(0n),
  portfolioWizardIsDeposit: parseAsBoolean.withDefault(true),
} as const;

export const assembleSearchParamsForWizards = createSerializer({
  ...donationWizardParsers,
  ...fundWizardParsers,
  ...portfolioWizardParsers,
  ...targetAllocationModalParsers,
});
export type SearchParamsUsedByWizards = Parameters<typeof assembleSearchParamsForWizards>[1];
