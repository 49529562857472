import { Flex } from '@chakra-ui/react';
import { useInView } from 'motion/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';

import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, BankIcon, CollaborateIcon, LogoIcon, PrivateWealthIcon } from '@endaoment-frontend/ui/icons';
import { Button, CardDotsBackground } from '@endaoment-frontend/ui/shared';
import { useTypeformModal } from '@endaoment-frontend/ui/smart';

import iconHands from '../advisors/images/icon/hands.png';
import iconRegister from '../advisors/images/icon/register.png';

import styles from './ForAdvisorsFeatureSection.module.scss';

export const ForAdvisorsFeatureSection = () => {
  const { openTypeform: openDemoTypeform } = useTypeformModal({ id: 'B6A3Kqgd' });

  const containerRef = useRef(null);
  const inView = useInView(containerRef, { margin: '0px 0px -35% 0px' });

  return (
    <section id='for-advisors-section' ref={containerRef} data-in-view={inView} className={styles['section']}>
      <div className={styles['box']}>
        <div className={styles['corner']}>
          <Image src={iconHands} alt='Hands icon' width={200} />
          <Image src={iconRegister} alt='Register icon' width={200} />
        </div>
        <Button
          as={Link}
          href={routes.marketing.advisors()}
          variation='portfolio'
          faded
          float={false}
          className={styles['title']}>
          <LogoIcon color='currentColor' />
          <h2>For Financial Advisors</h2>
          <ArrowIcon color='currentColor' width={28} />
        </Button>
        <h3>
          <b>Advisor-managed investments</b> for any DAF
        </h3>
        <h4>Optimize donations, retain AUM, and strengthen client relationships.</h4>
        <div className={styles['items']}>
          <div>
            <div>
              <BankIcon color='currentColor' width={60} />
              <h6>Cheaper and easier than the competition</h6>
            </div>
            <p>
              Enjoy one-time,&nbsp;
              <a href={routes.docs.feeSchedule({ hash: 'tiered-inbound-fee' })}>tiered admin fees</a>
              &nbsp;and competitive expense ratios across portfolios that mean more money to recipient nonprofits.
            </p>
          </div>
          <div>
            <div>
              <CollaborateIcon color='currentColor' width={60} />
              <h6>No more faxed forms or automated phone calls</h6>
            </div>
            <p>
              Our unified, seamless and entirely online giving experience lets you say goodbye to third parties, clunky
              apps or multiple DAF providers.
            </p>
          </div>
          <div>
            <div>
              <PrivateWealthIcon color='currentColor' width={60} />
              <h6>No minimums and no new custodians</h6>
            </div>
            <p>
              Quickly establish management oversight of donated capital inside your existing custodial infrastructure at
              no minimum balance—an industry first.
            </p>
          </div>
        </div>
        <Flex gap='0.75rem'>
          <Button
            variation='gray'
            as='a'
            href='https://drive.google.com/open?id=1Rllr77TPp1Da4m3cNSQnLyZhRQKGtZMH&usp=drive_fs'
            target='blank'
            filled>
            View Data Room
          </Button>
          <Button variation='portfolio' onClick={() => openDemoTypeform()} filled>
            Request a Demo
          </Button>
        </Flex>
        <CardDotsBackground className={styles['dots']} excludeCorner />
      </div>
    </section>
  );
};
