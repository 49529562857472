import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { forwardRef, type ElementType } from 'react';

import type { ChildrenType, PolymorphicProps, PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Button.module.scss';

declare module 'react' {
  /** Fixed Ref typings (They suck) */
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const buttonClassNames = {
  base: styles.button,
  variants: {
    allocation: styles['variation--allocation'],
    default: styles['variation--default'],
    faded: styles['variation--faded'],
    fund: styles['variation--fund'],
    org: styles['variation--org'],
    portfolio: styles['variation--portfolio'],
    purple: styles['variation--purple'],
    red: styles['variation--red'],
    gray: styles['variation--gray'],
    green: styles['variation--green'],
  },
  sizes: {
    large: styles['size--large'],
    medium: styles['size--medium'],
    small: styles['size--small'],
    tiny: styles['size--tiny'],
  },
  fontSize: {
    large: styles['font-size--large'],
    medium: styles['font-size--medium'],
    small: styles['font-size--small'],
    tiny: styles['font-size--tiny'],
  },
  minimal: styles.minimal,
  float: styles.float,
  gradient: styles.gradient,
  filled: styles.filled,
  faded: styles.faded,
  shadowed: styles['shadowed'],
} as const;

type InternalButtonProps = {
  variation?: keyof (typeof buttonClassNames)['variants'];
  shadowed?: boolean;
  float?: boolean;
  filled?: boolean;
  faded?: boolean;
  size?: keyof (typeof buttonClassNames)['sizes'];
  fontSize?: keyof (typeof buttonClassNames)['fontSize'];
  minimal?: boolean;
  gradient?: boolean;
  children: ChildrenType;
};
export type ButtonProps<Tag extends ElementType> = PolymorphicProps<'button', Tag, InternalButtonProps>;

const ButtonWithRef = <Tag extends ElementType>(
  {
    variation = 'default',
    size = 'medium',
    fontSize,
    minimal = false,
    filled = false,
    float = true,
    shadowed = false,
    gradient = false,
    faded = false,
    className,
    children,
    as,
    ...restProps
  }: ButtonProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  const Component = as ?? 'button';

  return (
    <Box
      as={Component}
      ref={ref}
      {...restProps}
      type={Component === 'button' ? (restProps.type ?? 'button') : undefined}
      data-filled={filled}
      className={clsx(
        buttonClassNames.base,
        buttonClassNames.variants[variation],
        buttonClassNames.sizes[size],
        buttonClassNames.fontSize[fontSize ?? size],
        minimal && buttonClassNames.minimal,
        float && buttonClassNames.float,
        gradient && buttonClassNames.gradient,
        filled && buttonClassNames.filled,
        faded && buttonClassNames.faded,
        shadowed && buttonClassNames.shadowed,
        className,
      )}>
      {children}
    </Box>
  );
};

export const Button = forwardRef(ButtonWithRef);
