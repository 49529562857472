import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import type { ReactNode } from 'react';

import styles from './Input.module.scss';

export const ErrorDisplay = ({
  error,
  rightAligned = false,
  className,
}: {
  error: ReactNode;
  rightAligned?: boolean;
  className?: string;
}) => {
  return (
    <AnimatePresence>
      {!!error && (
        <motion.span
          className={clsx(styles.error, rightAligned && styles['error--right'], className)}
          data-component='error'
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={{ hidden: { opacity: 0, y: '-30%', height: 0 }, visible: { opacity: 1, y: 0, height: '100%' } }}
          transition={{ duration: 0.3 }}>
          {error}
        </motion.span>
      )}
    </AnimatePresence>
  );
};
