import type { Portfolio, PortfolioCategory, PortfolioFinancial } from '@endaoment-frontend/types';
import { arrToSorted } from '@endaoment-frontend/utils';

export const comparePortfoliosForSort = <MinimalPortfolio extends Pick<Portfolio, 'id' | 'type'>>(
  a: MinimalPortfolio,
  b: MinimalPortfolio,
): number => {
  // Sort by type
  if (a.type !== b.type) {
    // Always place PrivateWealth portfolios first
    if (a.type === 'PrivateWealth') return -1;
    if (b.type === 'PrivateWealth') return 1;
    return a.type.localeCompare(b.type);
  }
  // Sort by id
  return a.id.localeCompare(b.id);
};
export const sortPortfolios = <MinimalPortfolio extends Pick<Portfolio, 'id' | 'type'>>(
  portfolios: Array<MinimalPortfolio>,
): Array<MinimalPortfolio> => arrToSorted(portfolios, comparePortfoliosForSort);

export const getAllocatablePortfolios = (
  portfolios: Array<PortfolioFinancial> | undefined,
  filterChainIds: Array<number> | undefined,
  allowExceedingCap = false,
): Array<PortfolioFinancial> => {
  if (!portfolios) return [];

  return portfolios.filter(
    p =>
      p.enabled &&
      // Filter out portfolios that have their cap maxed out
      (allowExceedingCap || !p.cap || p.totalInvestedInPortfolio < p.cap) &&
      // Filter out portfolios that are on a different chain than the currently selected one
      (!filterChainIds || filterChainIds.length === 0 || filterChainIds.includes(p.chainId)),
  );
};

export const filterPortfolios = (
  portfolios: Array<PortfolioFinancial> | undefined,
  filters: {
    search: string;
    chainIds: Array<PortfolioFinancial['chainId']>;
    categoryIds: Array<PortfolioCategory['id']>;
  },
  options?: {
    allowExceedingCap?: boolean;
  },
) => {
  const allocatablePortfolios = getAllocatablePortfolios(portfolios, filters.chainIds, options?.allowExceedingCap);

  // Filter based on search term
  const normalizedSearch = filters.search.toLowerCase();
  let searchFiltered = allocatablePortfolios;
  if (normalizedSearch) {
    searchFiltered = allocatablePortfolios.filter(
      p =>
        (p.shortName && p.shortName.toLowerCase().includes(normalizedSearch)) ||
        p.name.toLowerCase().includes(normalizedSearch) ||
        p.description.toLowerCase().includes(normalizedSearch) ||
        p.chainId.toString().includes(normalizedSearch),
    );
  }

  // Filter based on categories
  let categoryFiltered = searchFiltered;
  if (filters.categoryIds.length > 0) {
    categoryFiltered = searchFiltered.filter(p => {
      for (const cId of filters.categoryIds) {
        if (p.categories.some(pc => pc.id === cId)) return true;
      }
      return false;
    });
  }

  return categoryFiltered;
};
