import { useInView } from 'motion/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';

import { routes } from '@endaoment-frontend/routes';
import {
  AllocationIcon,
  ArrowIcon,
  ClaimedIcon,
  EthereumIcon,
  LogoIcon,
  ShieldIcon,
  StarIcon,
} from '@endaoment-frontend/ui/icons';
import { Button, CardDotsBackground, Pill } from '@endaoment-frontend/ui/shared';

import { GetStartedButton } from '../../components/GetStartedButton';
import custodiansShot from '../../images/logos/custodiansShot.png';
import donationOptionsShot from '../../images/screenshots/home/donation-options.png';
import stockDonationShot from '../../images/screenshots/home/stock-donation.png';

import styles from './ForDonorsFeatureSection.module.scss';

export const ForDonorsFeatureSection = () => {
  const containerRef = useRef(null);
  const inView = useInView(containerRef, { margin: '0px 0px -35% 0px' });

  return (
    <section id='for-donors-section' data-in-view={inView} className={styles['section']} ref={containerRef}>
      <div className={styles['box']}>
        <div className={styles['header']}>
          <Button
            as={Link}
            href={routes.marketing.donors()}
            variation='fund'
            faded
            float={false}
            className={styles['title']}>
            <LogoIcon color='currentColor' />
            <h2>For Donors</h2>
            <ArrowIcon color='currentColor' width={28} />
          </Button>
        </div>
        <div className={styles['spread']}>
          <div className={styles['details']}>
            <div>
              <LogoIcon width='100%' className={styles['app-icon']} />
              <h3 id='for-donors-feature-title'>
                Give&nbsp;<b>anything</b>
                <br />
                Grant&nbsp;<b>anywhere</b>
              </h3>
            </div>
            <h4 id='for-donors-feature-description'>
              Make in-app donations of stock, cash, crypto, private assets and more. Invest tax-free and grant when
              ready to any nonprofit.
            </h4>
            <div>
              <Pill variation='purple' size='small'>
                <EthereumIcon color='currentColor' width={24} height={24} />
                Multi-Asset Acceptance
              </Pill>
              <Pill variation='purple' size='small'>
                <ShieldIcon color='currentColor' width={24} height={24} />
                Verifiable Receipts
              </Pill>
              <Pill variation='purple' size='small'>
                <StarIcon color='currentColor' width={24} height={24} />
                Delightful Interface
              </Pill>
              <Pill variation='purple' size='small'>
                <AllocationIcon color='currentColor' width={24} height={24} />
                Custom Investing
              </Pill>
              <Pill variation='purple' size='small'>
                <ClaimedIcon color='currentColor' width={24} height={24} />
                1.8m+ Nonprofits
              </Pill>
            </div>
            <GetStartedButton smaller />
          </div>
          <div className={styles['shots']}>
            <Image src={stockDonationShot} alt='Stock Donation Shot' />
            <Image src={donationOptionsShot} alt='Stock Donation Shot' />
          </div>
        </div>
        <div className={styles['safety']}>
          <div>
            <h3>Transparent and Trustworthy</h3>
            <p>
              We utilize leading asset custodians and premier nonprofit diligence providers. Enjoy a reliable, safe, and
              flexible home for every kind of giving.
            </p>
            <Button as={Link} href={routes.docs.protectionOfAssets()} variation='gray' filled>
              Learn More
              <ArrowIcon />
            </Button>
          </div>
          <div>
            <Image src={custodiansShot} alt='Custodians & Technology' />
          </div>
        </div>
        <CardDotsBackground className={styles['dots']} excludeCorner />
      </div>
    </section>
  );
};
