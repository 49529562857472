import { base, mainnet, optimism } from 'viem/chains';

import type { ConfigEntry } from './config.types';

export const config: ConfigEntry = {
  environmentName: 'production',
  chains: [mainnet, base, optimism],
  baseUrls: {
    app: 'https://app.endaoment.org',
    marketing: 'https://endaoment.org',
    api: 'https://api.endaoment.org',
    developers: 'https://developers.endaoment.org',
    docs: 'https://docs.endaoment.org',
    oauth: 'https://oauth.endaoment.org',
  },
  socialLoginChainId: optimism.id,
  zeroDev: {
    projectId: 'c7fec173-a16c-4c2e-9708-f65b2a3fb1c1',
    bundlerRpcUrl: 'https://rpc.zerodev.app/api/v2/bundler/c7fec173-a16c-4c2e-9708-f65b2a3fb1c1',
    paymasterRpcUrl: 'https://rpc.zerodev.app/api/v2/paymaster/c7fec173-a16c-4c2e-9708-f65b2a3fb1c1',
  },
  privyAppId: 'clvmg8vf6057btmusn43z3oef',
  circleAddress: '0xf0bfff7045dfeb4f6e69350c1cf8ff1a7de0d7da',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  heapAppId: '142404605',
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  identifiersSecret: 'f33f3c5a-a716-4a5a-94bf-6cf7d86199a83a4cfa0a-1a52-4c30-98c9-7bc38ab6bbb3',
};
