import type { GetStaticProps, NextPage } from 'next';

import { GetFeaturedFunds, GetFeaturedOrgs, GetTotalDonations, GetTotalGrants } from '@endaoment-frontend/api';
import { TIME_ONE_HOUR_IN_SECONDS } from '@endaoment-frontend/constants';
import { makeDehydratedQueries } from '@endaoment-frontend/data-fetching';

import { MetaTags } from '../components/MetaTags';
import { PageLayout } from '../components/PageLayout';
import { TestimonialCarousel } from '../components/TestimonialCarousel';
import { ForAdvisorsFeatureSection } from '../content/home/ForAdvisorsFeatureSection';
import { ForDonorsFeatureSection } from '../content/home/ForDonorsFeatureSection';
import { ForNonprofitsFeatureSection } from '../content/home/ForNonprofitsFeatureSection';
import { Hero } from '../content/home/Hero';
import { PortfoliosFeatureSection } from '../content/home/PortfoliosFeatureSection';
import { UpgradeSection } from '../content/home/UpgradeSection';
import { homePage } from '../metadata';

import styles from './index.module.scss';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      dehydratedState: await makeDehydratedQueries(
        GetTotalDonations.getDehydratedQueryArgs([]),
        GetTotalGrants.getDehydratedQueryArgs([]),
        GetFeaturedFunds.getDehydratedQueryArgs([]),
        GetFeaturedOrgs.getDehydratedQueryArgs([]),
      ),
    },
    // ISR every hour
    revalidate: TIME_ONE_HOUR_IN_SECONDS,
  };
};

const Home: NextPage = () => {
  return (
    <PageLayout>
      <MetaTags data={homePage} />
      <Hero />
      <div className={styles.index__container}>
        <ForDonorsFeatureSection />
        <ForAdvisorsFeatureSection />
        <PortfoliosFeatureSection />
        <ForNonprofitsFeatureSection />
        <UpgradeSection />
        <TestimonialCarousel />
      </div>
    </PageLayout>
  );
};

export default Home;
