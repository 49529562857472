/** This file is autogenerated using the production environment */
    
import type { DefaultsEntry } from './config.types';
    
export { config } from './production.config';
export const defaults: DefaultsEntry = {
  confirmations: {
    donation: 2,
    wirePayout: 7,
    walletPayout: 5,
    portfolioTrade: 2,
    transfer: 2,
    setManager: 2,
    orgDeployment: 2,
    fundDeployment: 2,
  },
  fees: {
    donationBps: 150,
    transferBps: 100,
    stockDonationFeeBps: 150,
    tgbEntityStockDonationFeeBps: 150,
  },
  network: {
    defaultChainId: 1,
    supportedNetworkSettings: [
      {
        chainId: 1,
        baseToken: {
          id: 18,
          symbol: 'USDC',
          name: 'USD//C',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 1,
          contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        },
        nativeToken: {
          id: 8,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 1,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0x94106ca9c7e567109a1d39413052887d1f412183',
          orgFundFactory: '0x10fd9348136dcea154f752fe0b6db45fc298a589',
          batchOrgDeployer: '0x5012cb8a192db05260673a795b18aa5329d3d4c2',
          transferDeployer: '0x4d91aad0deb494c71d538039c8c5d689faffa5fc',
          entityMulticall: '0xf15c683ffba50254bab340efe49fa2038ee9354c',
        },
      },
      {
        chainId: 8453,
        baseToken: {
          id: 1056,
          symbol: 'USDC',
          name: 'USD Coin',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 8453,
          contractAddress: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
        },
        nativeToken: {
          id: 1026,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 100,
          chainId: 8453,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0x237b53bcfbd3a114b549dfec96a9856808f45c94',
          orgFundFactory: '0x10fd9348136dcea154f752fe0b6db45fc298a589',
          batchOrgDeployer: '0x0000000000000000000000000000000000000000',
          transferDeployer: '0x2db36badc879192acc9fbbfa5d5a4f3c17595812',
          entityMulticall: '0x2a87a5495b315d62bca6a184f792ba144f4983ab',
        },
      },
      {
        chainId: 10,
        baseToken: {
          id: 1034,
          symbol: 'USDC',
          name: 'USD Coin',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
          type: 'EvmToken',
          featured: true,
          popularity: 0,
          chainId: 10,
          contractAddress: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
        },
        nativeToken: {
          id: 1033,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: true,
          popularity: 10,
          chainId: 10,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0x5d216bb28852f98ceaa29180670397ab01774ea6',
          orgFundFactory: '0x10fd9348136dcea154f752fe0b6db45fc298a589',
          batchOrgDeployer: '0x51aa0ad438b1efea69dbfd64bc15b6937edea504',
          transferDeployer: '0x1d6834f708780dec04a7c33decb3bfe2397aefb9',
          entityMulticall: '0x9abd2f47a1a1661adf9170048c6ba6f3943ab13e',
        },
      },
    ],
  },
  roles: {
    keeper: [
      '0x88100996d867585258f88b01cfe44cb60119eef0',
      '0xbf8e57a8195eed88f7663acf2af0b7eacdbd69bc',
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x0cb1d93daec77df2ed7db31c040fd2174452bd9f',
      '0xc6f1beb66fa3d40b818f969d219958947cd4b448',
    ],
    accountant: ['0x9d5025b327e6b863e5050141c987d988c07fd8b2'],
    maintainer: [
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x5398785700a9248cfc2101f0188c9788e8619974',
      '0x77dfb7afae21622274751358d01ad5f1d59d202a',
      '0x9d5025b327e6b863e5050141c987d988c07fd8b2',
    ],
    reviewer: ['0xbe21e4cf884c8b2517e4e199487f8b505841cb36'],
  },
  stripePublishableKey:
    'pk_live_51NlG4xJjtfgbRoqpxUU4fFOwZYalkcVt9rCrvASS9zOrtgLvVq2lIOrmirw0Qyc7sRcILgsjyYCIOzt0WBYR9KCL004tbrOKdt',
};
