import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ElementType, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import type { PolymorphicProps } from '@endaoment-frontend/types';

import styles from './Pill.module.scss';

type PillProps<Tag extends ElementType> = PolymorphicProps<
  'span',
  Tag,
  {
    children: ReactNode;
    className?: string;
    outline?: boolean;
    rectangle?: boolean;
    fill?: boolean;
    variation?: 'blue' | 'green' | 'orange' | 'purple' | 'red' | 'violet';
    size?: 'large' | 'small' | 'tiny';
    pad?: boolean;
    onClick?: () => void;
  }
>;

const PillWithRef = <Tag extends ElementType>(
  {
    children,
    className,
    rectangle,
    outline,
    variation,
    fill,
    size = 'small',
    onClick,
    as,
    pad,
    ...props
  }: PillProps<Tag>,
  ref: Ref<Tag>,
) => {
  return (
    <Box
      as={as ?? 'span'}
      ref={ref}
      className={clsx(
        styles['pill'],
        className,
        onClick && styles['selectable'],
        outline && styles['outline'],
        rectangle && styles['rectangle'],
        pad && styles['pad'],
        fill && styles['fill'],
        styles[`size-${size}`],
        variation && styles[`color-${variation}`],
      )}
      onClick={onClick}
      {...props}>
      {children}
    </Box>
  );
};
export const Pill = forwardRef(PillWithRef);
