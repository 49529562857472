import clsx from 'clsx';

import { BigLoadingIcon, LoadingIcon } from '@endaoment-frontend/ui/icons';

import styles from './Loader.module.scss';

type LoaderProps = {
  loading?: boolean;
  size?: 'l' | 's';
  white?: boolean;
  className?: string;
};

export const Loader = ({ loading = true, size = 's', white = false, className }: LoaderProps) => {
  return (
    <div
      className={clsx(
        styles['loader'],
        className,
        white && styles['loader--white'],
        loading && styles['loader--loading'],
        size && styles[`loader--${size}`],
      )}
      role='status'>
      {size === 's' && <LoadingIcon />}
      {size === 'l' && <BigLoadingIcon width={50} height={50} strokeWidth={5} />}
    </div>
  );
};

export const CenteredPageLoader = ({ wrapperClassName, ...props }: LoaderProps & { wrapperClassName?: string }) => {
  return (
    <div className={clsx(styles['centered-loader'], wrapperClassName)}>
      <Loader size='l' {...props} />
    </div>
  );
};
